import React from "react";
import keplerGlReducer, {uiStateUpdaters} from 'kepler.gl/reducers';
import KeplerGl from 'kepler.gl';
import { createStore, combineReducers, applyMiddleware } from 'redux';
import { taskMiddleware } from 'react-palm/tasks';
import { Provider, useDispatch } from 'react-redux'
import { addDataToMap } from 'kepler.gl/actions'
import useSWR from "swr";
import base64 from "base-64"; 

const reducers = combineReducers({
    keplerGl: keplerGlReducer.initialState({
      uiState: {
        // hide side panel to disallower user customize the map
        readOnly: true,

        currentModal: null,
  
        // customize which map control button to show
        mapControls: {
          ...uiStateUpdaters.DEFAULT_MAP_CONTROLS,
          visibleLayers: {
            show: false
          },
          mapLegend: {
            show: true,
            active: true
          },
          toggle3d: {
            show: false
          },
          splitMap: {
            show: false
          }
        }
      }
    }),
});

const store = createStore(reducers, {}, applyMiddleware(taskMiddleware));

function App() {
  return (
      <Provider store={store}>
          <Map />
      </Provider>
  );
};

const transformStations = (stations) => {

  let formattedStations = {
    fields: [
      {
        "name": "id",
        "format": "",
        "type": "string"
      },
      {
        "name": "Nom",
        "format": "",
        "type": "string"
      },
      {
        "name": "latitude",
        "format": "",
        "type": "real"
      },
      {
        "name": "longitude",
        "format": "",
        "type": "real"
      },
      {
        "name": "Vélos à la borne",
        "format": "",
        "type": "real"
      },
      {
        "name": "Vélos fonctionnels",
        "format": "",
        "type": "real"
      },
      {
        "name": "Vélos non fonctionnels",
        "format": "",
        "type": "real"
      },
      {
        "name": "Proportion de vélos fonctionnels",
        "format": "",
        "type": "real"
      },
    ],
    rows: []
  }

  stations.forEach(s => {

    let nbDocks = s['nbDock'] + s['nbEDock']

    if (s['overflow'] === "yes" && s['overflowActivation'] === "yes") {
      nbDocks += s['maxBikeOverflow']
    }

    let nbBikes = s['bikes'].length
    let nbUnavailable = s['bikes'].filter(b => b['bikeStatus'] === "indisponible").length
    let nbAvailable = nbBikes - nbUnavailable
    let availabilityRate = nbBikes ? nbAvailable / nbBikes : 0

    formattedStations.rows.push([
      s['station']['code'],
      s['station']['name'],
      s['station']['gps']['latitude'],
      s['station']['gps']['longitude'],
      nbAvailable + nbUnavailable,
      nbAvailable,
      nbUnavailable,
      availabilityRate
    ])
    
  });

  return formattedStations
}

const Map = () => {

    const dispatch = useDispatch();

        
    const user = 'moba'
    const password = '$2y$10$EsIaI6.Dle8uzRF.VFLEJu2y02dslH.v7qEoRBGgN50seuFiRE57.'

    const headers = new Headers({
      "Authorization": `Basic ${base64.encode(`${user}:${password}`)}`,
      "Content-type": "application/json; charset=UTF-8"
    })

    const body = JSON.stringify({
        'stationName': '',
        'disponibility': 'yes'
    })

    const fetcher = url => fetch(
      url,
      {
        method: 'POST',
        headers: headers,
        body: body
      }
    ).then(r => r.json()).catch(err => console.log('Error: ', err))

    const url = 'https://www.velib-metropole.fr/api/secured/searchStation'
    const { data: stations, error: stationsError } = useSWR(url, fetcher)

    React.useEffect(() => {
        if (stations) {
            dispatch(
                addDataToMap({
                    datasets: [
                        {
                            info: {
                                label: 'Velib stations activity',
                                id: 'velib-stations'
                            },
                            data: transformStations(stations)
                        }
                    ],
                    // option
                    options: {
                        centerMap: true,
                        readOnly: true 
                    },
                    // config
                    config: {
                      "version": "v1",
                      "config": {
                        "visState": {
                          "filters": [],
                          "layers": [
                            {
                              "id": "nc2o8x",
                              "type": "point",
                              "config": {
                                "dataId": "velib-stations",
                                "label": "Point",
                                "color": [
                                  255,
                                  203,
                                  153
                                ],
                                "highlightColor": [
                                  252,
                                  242,
                                  26,
                                  255
                                ],
                                "columns": {
                                  "lat": "latitude",
                                  "lng": "longitude",
                                  "altitude": null
                                },
                                "isVisible": true,
                                "visConfig": {
                                  "radius": 10,
                                  "fixedRadius": false,
                                  "opacity": 0.65,
                                  "outline": false,
                                  "thickness": 2,
                                  "strokeColor": null,
                                  "colorRange": {
                                    "name": "ColorBrewer RdYlGn-10",
                                    "type": "diverging",
                                    "category": "ColorBrewer",
                                    "colors": [
                                      "#a50026",
                                      "#d73027",
                                      "#f46d43",
                                      "#fdae61",
                                      "#fee08b",
                                      "#d9ef8b",
                                      "#a6d96a",
                                      "#66bd63",
                                      "#1a9850",
                                      "#006837"
                                    ]
                                  },
                                  "strokeColorRange": {
                                    "name": "Global Warming",
                                    "type": "sequential",
                                    "category": "Uber",
                                    "colors": [
                                      "#5A1846",
                                      "#900C3F",
                                      "#C70039",
                                      "#E3611C",
                                      "#F1920E",
                                      "#FFC300"
                                    ]
                                  },
                                  "radiusRange": [
                                    5,
                                    25
                                  ],
                                  "filled": true
                                },
                                "hidden": false,
                                "textLabel": []
                              },
                              "visualChannels": {
                                "colorField": {
                                  "name": "Proportion de vélos fonctionnels",
                                  "type": "real"
                                },
                                "colorScale": "quantize",
                                "strokeColorField": null,
                                "strokeColorScale": "quantile",
                                "sizeField": {
                                  "name": "Vélos à la borne",
                                  "type": "integer"
                                },
                                "sizeScale": "sqrt"
                              }
                            }
                          ],
                          "interactionConfig": {
                            "tooltip": {
                              "fieldsToShow": {
                                "velib-stations": [
                                  {
                                    "name": "Nom",
                                    "format": null
                                  },
                                  {
                                    "name": "Vélos à la borne",
                                    "format": null
                                  },
                                  {
                                    "name": "Vélos fonctionnels",
                                    "format": null
                                  },
                                  {
                                    "name": "Vélos non fonctionnels",
                                    "format": null
                                  },
                                  {
                                    "name": "Proportion de vélos fonctionnels",
                                    "format": null
                                  }
                                ]
                              },
                              "compareMode": false,
                              "compareType": "absolute",
                              "enabled": true
                            },
                            "brush": {
                              "size": 0.5,
                              "enabled": false
                            },
                            "geocoder": {
                              "enabled": false
                            },
                            "coordinate": {
                              "enabled": false
                            }
                          },
                          "layerBlending": "normal",
                          "splitMaps": [],
                          "animationConfig": {
                            "currentTime": null,
                            "speed": 1
                          }
                        },
                        "mapState": {
                          "bearing": 0,
                          "dragRotate": false,
                          "latitude": 48.8568683054881,
                          "longitude": 2.3427780425575766,
                          "pitch": 0,
                          "zoom": 12,
                          "isSplit": false
                        },
                        "mapStyle": {
                          "styleType": "dark",
                          "topLayerGroups": {},
                          "visibleLayerGroups": {
                            "label": false,
                            "road": true,
                            "border": false,
                            "building": true,
                            "water": true,
                            "land": true,
                            "3d building": false
                          },
                          "threeDBuildingColor": [
                            9.665468314072013,
                            17.18305478057247,
                            31.1442867897876
                          ],
                          "mapStyles": {}
                        }
                      }
                    }
                })
            );
        }
    }, [dispatch, stations])

    return (
        <KeplerGl 
            id='velib'
            mapboxApiAccessToken={'pk.eyJ1IjoiamVubnRlZHJhIiwiYSI6ImNrd2Nkbnd2eTBhODkydW4xczhpOGY3dHoifQ.kRQ2njCxc5X7oYNFoHI2wg'} 
            width={window.innerWidth}
            height={window.innerHeight}
        />
    )
};

export default App;
